<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('headquarters.hqTitle') }} {{ $t('common.create') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <!-- Basic Info Start-->
        <div class="ion-modal-form-group-title">{{ $t('headquarters.basicInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="hq-create-name">
              <span>{{ $t('headquarters.hqName') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-create-name"
                          v-model="form.hqName"
                          :class="{ 'is-invalid': submitted && $v.form.hqName.$error }" />
            <div v-if="submitted && $v.form.hqName.$error" class="invalid-feedback">
              <span v-if="!$v.form.hqName.required">{{ $t('validation.hqNameIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="hq-create-busi-no">{{ $t('headquarters.businessNo') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-create-busi-no"
                          v-model="form.businessNo" />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-4">
            <label class="form-label" for="hq-create-tel">
              <span>{{ $t('headquarters.tel') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-create-tel"
                          v-model="form.tel"
                          :class="{ 'is-invalid': submitted && $v.form.tel.$error }" />
            <div v-if="submitted && $v.form.tel.$error" class="invalid-feedback">
              <span v-if="!$v.form.tel.required">{{ $t('validation.busiCallIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-8">
            <label class="form-label" for="hq-create-address">
              <span>{{ $t('headquarters.address') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-create-address"
                          v-model="form.address"
                          :class="{ 'is-invalid': submitted && $v.form.address.$error }" />
            <div v-if="submitted && $v.form.address.$error" class="invalid-feedback">
              <span v-if="!$v.form.address.required">{{ $t('validation.addressIsRequired') }}</span>
            </div>
          </div>
        </div>
        <!-- Basic Info End -->

        <!-- Plan Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('headquarters.planInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="hq-create-plan">
              <span>{{ $t('headquarters.planName') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <multiselect id="hq-create-plan"
                         v-model="form.plan"
                         label="text" track-by="value"
                         :options="planOptions"
                         :placeholder="$t('alert.selectPlan')"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         :searchable="false" :allow-empty="false"
                         @input="setDefaultPlanInfo" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="hq-create-plan-date">{{ $t('headquarters.planDate') }}</label>
            <div class="row">
              <date-picker type="date" format="YYYY-MM-DD" id="valid-to-date"
                           v-model="form.planDate"
                           value-type="format"
                           :disabled-date="notBeforeToday" />
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-4">
            <label class="form-label" for="hq-create-server-fee">{{ $t('headquarters.serverUsageFee') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-create-server-fee"
                          v-model="form.serverUsageFee" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="hq-create-settlement-rate">{{ $t('headquarters.settleRate') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-create-settlement-rate"
                          v-model="form.settleRate" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="hq-create-profit-dist-rate">{{ $t('headquarters.profitDistribution') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="hq-create-profit-dist-rate"
                          :disabled="form.plan?.value === 10000000000224"
                          v-model="form.profitDistRate" />
          </div>
        </div>
        <!-- Plan Info End -->

        <!-- Settlement Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('headquarters.settlementInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="hq-create-bank">{{ $t('headquarters.bankName') }}</label>
            <b-form-input class="form-control" type="text" id="hq-create-bank" v-model="form.bank" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="hq-create-settlement-day">{{ $t('headquarters.settleDay') }}</label>
            <div class="row">
              <div class="col-2 text-center align-content-center">{{ $t('headquarters.monthly') }}</div>
              <div class="col-4">
                <b-form-input class="form-control" type="text" id="hq-create-settlement-day" v-model="form.settleDay" />
              </div>
              <div class="col-6 text-start align-content-center">{{ $t('headquarters.day') }}</div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-6">
            <label class="form-label" for="hq-create-account-holder">{{ $t('headquarters.accountHolder') }}</label>
            <b-form-input class="form-control" type="text" id="hq-create-account-holder" v-model="form.bankAccountHolder" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="hq-create-account-number">{{ $t('headquarters.accountNumber') }}</label>
            <b-form-input class="form-control" type="text" id="hq-create-account-number" v-model="form.bankAccountNo" />
          </div>
        </div>
        <!-- Settlement Info End -->

        <!-- Point Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('headquarters.pointInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-3">
            <label class="form-label" for="hq-create-point-use">{{ $t('headquarters.pointUseYn') }}</label>
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="pointUse1" autocomplete="off"
                     v-model="form.isAllowPointSaving" :value="false" @click="form.pointSavingRate = null" />
              <label class="btn btn-outline-secondary" for="pointUse1">{{ $t('common.unused') }}</label>
              <input type="radio" class="btn-check" id="pointUse2" autocomplete="off"
                     v-model="form.isAllowPointSaving" :value="true" @click="form.pointSavingRate = 0" />
              <label class="btn btn-outline-secondary" for="pointUse2">{{ $t('common.use') }}</label>
            </div>
          </div>
          <div class="col-xl-3">
            <template v-if="form.isAllowPointSaving">
              <label class="form-label" for="hq-create-point-rate">{{ $t('headquarters.pointSaveRate') }} (%)</label>
              <b-form-input class="form-control" type="text" id="hq-create-point-rate" v-model="form.pointSavingRate" />
            </template>
          </div>
          <div class="col-xl-3">
            <template v-if="form.isAllowPointSaving">
              <label class="form-label" for="hq-create-affiliation">{{ $t('headquarters.affiliation') }}</label>
              <b-form-input class="form-control" type="text" id="hq-create-affiliation" v-model="form.affiliationName" />
            </template>
          </div>
          <div class="col-xl-3">
            <template v-if="form.isAllowPointSaving">
              <label class="form-label" for="hq-create-point-logo">{{ $t('headquarters.pointLogo') }}</label>
              <input class="form-control" type="file" accept="image/*" id="hq-create-point-logo"
                     ref="hqPointLogoInput" @change="handleFileChange" />
            </template>
          </div>
        </div>
        <!-- Point Info End -->

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';

export default {
  components: { Multiselect },
  data() {
    return {
      visible: false,
      submitted: false,
      planOptions: [],
      planDefaultInfos: null,
      form: {
        hqName: null,
        businessNo: null,
        tel: null,
        address: null,
        plan: null,
        planDate: null,
        serverUsageFee: null,
        settleRate: null,
        profitDistRate: null,
        settleDay: null,
        bank: null,
        bankAccountHolder: null,
        bankAccountNo: null,
        isAllowPointSaving: false,
        pointSavingRate: null,
        file: null,
        affiliationName: null
      }
    }
  },
  validations: {
    form: {
      hqName: { required },
      tel: { required },
      address: { required }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getPlanOptions();
      vm.visible = true;
    },
    getPlanOptions() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlacePlan/list';

      vm.planOptions = [];

      http.post(_url, {
        page: 1,
        limit: 10,
        searchConditions: [],
        sortCondition: { sortDirection: 'DESC' }
      }).then(res => {
        vm.planDefaultInfos = res.data.items;
        res.data.items.forEach(item => {
          vm.planOptions.push({ text: item.planName, value: item.id });
        });
      }).then(() => {
        vm.form.plan = vm.planOptions.find(ele => ele.value === 10000000000225);
        vm.setDefaultPlanInfo();
      }).catch(err => {
        console.error('Business Plan List Error :: ', err)
      });
    },
    notBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    setDefaultPlanInfo() {
      const vm = this;

      const selectedPlan = vm.planDefaultInfos.find(ele => ele.id === vm.form.plan.value)

      vm.form = {
        ...vm.form,
        serverUsageFee : selectedPlan.serverUsageFee,
        settleRate : selectedPlan.settleRate,
        profitDistRate : selectedPlan.profitDistRate,
      }
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.form = {
        hqName: null,
        businessNo: null,
        tel: null,
        address: null,
        plan: null,
        planDate: null,
        serverUsageFee: null,
        settleRate: null,
        profitDistRate: null,
        settleDay: null,
        bank: null,
        bankAccountHolder: null,
        bankAccountNo: null,
        isAllowPointSaving: false,
        pointSavingRate: null,
        file: null,
        affiliationName: null
      };
      vm.planOptions = [];

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    handleFileChange(e) {
      const vm = this;
      const _file = e.target.files[0];

      vm.form.file = _file;
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmCreate'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/Hq/create';

      const _formData = new FormData();

      _formData.append('hqName', vm.form.hqName);
      _formData.append('tel', vm.form.tel);
      _formData.append('address', vm.form.address);
      _formData.append('isAllowPointSaving', vm.form.isAllowPointSaving);

      const _formFields = [
        { key: 'businessNo', value: vm.form.businessNo },
        { key: 'serverUsageFee', value: vm.form.serverUsageFee },
        { key: 'settleRate', value: vm.form.settleRate },
        { key: 'commissionRate', value: vm.form.profitDistRate },
        { key: 'settleDay', value: vm.form.settleDay },
        { key: 'bank', value: vm.form.bank },
        { key: 'bankAccountHolder', value: vm.form.bankAccountHolder },
        { key: 'bankAccountNo', value: vm.form.bankAccountNo }
      ];

      _formFields.forEach(field => {
        if (!this.$_utils.isEmpty(field.value)) {
          _formData.append(field.key, field.value);
        }
      });

      if (vm.form.isAllowPointSaving) {
        _formData.append('pointSavingRate', vm.form.pointSavingRate);
        _formData.append('affiliationName', vm.form.affiliationName);
      }

      if (!this.$_utils.isEmpty(vm.form.plan)) {
        _formData.append('planId', vm.form.plan.value);
      }

      if (!this.$_utils.isEmpty(vm.form.planDate)) {
        _formData.append('planDate', vm.form.planDate + ' 00:00:00');
      }

      if (!this.$_utils.isEmpty(vm.form.file)) {
        _formData.append('file', vm.form.file);
      }

      http.post(_url, _formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.created'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Headquarters Create Error :: ', err);

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
